import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    timeout: {
      type: Number,
      default: 1000 // ms
    }
  }

  connect() {
    this.setTimer()
  }

  reload() {
    this.element.reload()
  }

  setTimer() {
    this.timeout = setTimeout(() => {
      this.reload()
      this.setTimer()
    }, this.timeoutValue)
  }
}
