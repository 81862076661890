import 'src/autocomplete'
import 'controllers'
import * as Turbo from '@hotwired/turbo'
import Chart from 'chart.js/auto'
import 'remixicon/fonts/remixicon.css'

require.context('../images', true)

Chart.defaults.font.size = 14
Chart.defaults.font.family = 'Rubik'
