import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    classname: String,
  }

  add(event) {
    event.target.classList.add(this.classnameValue)
  }
}
