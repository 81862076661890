import { Controller } from 'stimulus'
let debounce = require('lodash/debounce')

export default class extends Controller {
  static targets = ['submit']

  initialize() {
    this.submit = debounce(this.submit, 200).bind(this)
  }

  connect() {
    this.element.addEventListener('reorder:end', this.submit)
  }

  submit(event) {
    if (this.hasSubmitTarget) {
      this.element.requestSubmit(this.submitTarget)
    } else {
      this.element.requestSubmit()
    }
  }
}
