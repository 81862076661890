import { Controller } from 'stimulus'
import autosize from "autosize"

export default class extends Controller {
  connect() {
     autosize(this.element)

    this.resize = this.resize.bind(this)
    this.intersectionObserver = new IntersectionObserver(this.resize, {})
    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    autosize.destroy(this.element)
  }

  resize(event) {
    autosize.update(this.element)
  }
}
