import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    datetime: String,
    includeTime: Boolean,
  }

  getFormatOptions(includeTime = false) {
    const shortDate = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }

    const dateAndTime = {
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }

    return includeTime ? dateAndTime : shortDate
  }

  connect() {
    const date = new Date(this.datetimeValue)

    if (Number.isNaN(date)) {
      this.element.innerHTML = this.datetimeValue
    } else {
      const dateFormat = this.getFormatOptions(this.includeTimeValue)
      const dateFormatter = new Intl.DateTimeFormat('en-CA', dateFormat)
      this.element.innerHTML = dateFormatter.format(date)
    }
  }
}
