import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'source']
  static values = {
    successDuration: {
      type: Number,
      default: 2000,
    },
    successContent: {
      type: String,
      default: 'Copied!',
    },
  }

  connect() {
    if (!this.hasButtonTarget) return

    this.originalContent = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    let range

    if (document.body.createTextRange) {
      // IE (not tested)
      range = document.body.createTextRange()
      range.moveToElement(this.sourceTarget)
      range.select()
    } else if (window.getSelection) {
      // other browsers
      let selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(this.sourceTarget)
      selection.removeAllRanges()
      selection.addRange(range)
    }

    document.execCommand('copy')
    window.getSelection().removeAllRanges()
    this.copied()
  }

  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerText = this.successContentValue

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
    }, this.successDurationValue)
  }
}
