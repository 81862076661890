import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit']
  static values = {
    disableSubmit: Boolean,
    invalidClass: String,
  }

  connect() {
    this.selectors = '[required], [min]'
    this.boundValidate = this.validate.bind(this)
    this.boundInvalid = this.invalid.bind(this)
    this.boundClear = this.clear.bind(this)

    // set or clear the invalid class on each element if an invalid class is present
    if (this.hasInvalidClassValue) {
      this.element.querySelectorAll(this.selectors).forEach((el) => {
        el.addEventListener('invalid', this.boundInvalid)
        el.addEventListener('keydown', this.boundClear)
      })
    }

    // Validate on keyup if we're disabling the submit button when invalid, otherwise validate on submit
    if (this.hasDisableSubmitValue) {
      this.element.addEventListener('keyup', this.boundValidate)
      this.element.dispatchEvent(new Event('keyup')) // check once on initial load
    } else {
      this.element.addEventListener('submit', this.boundValidate)
    }
  }

  validate(e) {
    let isInvalid = false

    // check each element's validity
    this.element.querySelectorAll(this.selectors).forEach((el) => {
      if (!el.checkValidity()) {
        isInvalid ||= true
      }
    })

    if (this.hasSubmitTarget && this.disableSubmitValue) {
      if (isInvalid) {
        this.submitTarget.setAttribute('disabled', 'disabled')
      } else {
        this.submitTarget.removeAttribute('disabled')
      }
    }
  }

  invalid(e) {
    // e.preventDefault()
    // if we want to override the message or add tooltips, do so here
    e.target.classList.add(this.invalidClassValue)
  }

  clear(e) {
    e.target.classList.remove(this.invalidClassValue)
  }
}
