import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggle', 'form']
  static values = {
    focus: { type: Number, default: -1 },
  }

  select(event) {
    this.toggleTarget.classList.remove('show')
    this.formTarget.requestSubmit()
  }

  showForm(event) {
    event.preventDefault()
    this.toggleTarget.classList.add('show')
    this.element.querySelector('.autocomplete input[type=search]').focus()
  }

  hideForm(event) {
    setTimeout(() => {
      this.toggleTarget.classList.remove('show')
    }, 500)
  }
}
