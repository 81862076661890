import { Controller } from 'stimulus'
import MiniMasonry from 'minimasonry'
import { debounce } from 'lodash'

export default class extends Controller {
  connect() {
    this.initializeMasonryInstance()
    this.refresh = debounce(() => {
      this.removeEmptyTurboFrames()
      this.masonryInstance.layout()
    }, 100)
    this.element.addEventListener('turbo:frame-load', this.refresh)
  }

  disconnect() {
    this.masonryInstance.destroy()
  }

  removeEmptyTurboFrames() {
    this.element.querySelectorAll('turbo-frame[complete]:empty').forEach((element) => element.remove())
  }

  initializeMasonryInstance() {
    this.masonryInstance = new MiniMasonry({
      baseWidth: 325,
      container: this.element,
      gutter: 16,
      surroundingGutter: false,
      minify: false,
      ultimateGutter: 16
    })
  }
}
