import { Controller } from 'stimulus'
import { get } from 'lodash'

export default class extends Controller {
  static targets = ['content', 'close']
  static values = {
    type: String,
  }

  connect() {
    document.addEventListener('overlay:success', this.close.bind(this))
    document.addEventListener('turbo:before-stream-render', this.beforeStreamRender.bind(this))
  }

  // This triggers before a turbo_stream is rendered, allowing us to trigger the overlay when we replace its content
  beforeStreamRender(e) {
    if (e.target.target == this.typeValue) {
      this.startLoading(e)
      e.detail.render(e.detail.newStream)
      this.stopLoading(e)
    }
  }

  startLoading(e) {
    if (e.target.querySelector('[data-overlay=keep-open]')) {
      return
    }
    this.element.classList.add('show')
    this.element.classList.add('loading')
  }

  stopLoading(e) {
    this.element.classList.remove('loading')
  }

  closeOnEsc(e) {
    if (e.code == 'Escape') {
      this.close()
    }
  }

  close() {
    this.element.classList.remove('show')
    this.contentTarget.removeAttribute('src')
    this.contentTarget.innerHTML = ''
  }

  closeSubmit(e) {
    const formSubmission = e.detail.formSubmission
    const overlay = get(formSubmission, 'submitter.dataset.overlay', 'default')

    if (overlay == 'keep-open') {
      return
    }

    if (formSubmission.method == 0) {
      // GET requests don't close the panel
      return
    }

    if (e.target.closest('[data-controller=overlay]') && e.detail.success) {
      this.dispatch('success')
    }
  }
}
