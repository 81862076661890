import { Controller } from 'stimulus'
import { computePosition, autoUpdate, flip, shift, offset, arrow } from '@floating-ui/dom'

function roundByDPR(value) {
  const dpr = window.devicePixelRatio || 1
  return Math.round(value * dpr) / dpr
}

export default class extends Controller {
  static targets = ['tooltip', 'arrow']
  static values = {
    position: String,
  }

  connect() {
    this.cleanup = autoUpdate(this.element, this.tooltipTarget, () => {
      this.update()
    })
  }

  disconnect() {
    this.cleanup()
  }

  update() {
    computePosition(this.element, this.tooltipTarget, {
      placement: this.positionValue,
      middleware: [offset(10), flip(), shift({ padding: 5 }), arrow({ element: this.arrowTarget })],
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(this.tooltipTarget.style, {
        left: '0',
        top: '0',
        transform: `translate(${roundByDPR(x)}px,${roundByDPR(y)}px)`,
      })

      this.applyPositionStyle(placement.split('-')[0])

      const { x: arrowX, y: arrowY } = middlewareData.arrow

      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.split('-')[0]]

      Object.assign(this.arrowTarget.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-5px',
      })
    })
  }

  show() {
    this.tooltipTarget.style.display = 'block'
    this.update()
  }

  hide() {
    this.tooltipTarget.style.display = ''
  }

  applyPositionStyle(placement) {
    this.tooltipTarget.setAttribute('data-position', placement)
  }
}
