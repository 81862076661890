import { Controller } from 'stimulus'
import { Chart } from 'chart.js/auto'
require('lodash/merge')

export default class extends Controller {
  static values = {
    type: String,
    data: Object,
    options: Object,
  }

  connect() {
    // expects this.element to be a <canvas>
    const context = this.element.getContext('2d')

    this.chart = new Chart(context, {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions,
    })

    this.lastLabel = ''
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData() {
    if (!this.hasDataValue) {
      console.warn('[stimulus-chartController] You need to pass data as JSON to see the chart.')
    }

    const chartData = this.dataValue
    const applyTheme = function (dataset, i) {
      const themeName = this.getThemeName(dataset)
      const datasetTheme = this.themeOptions(themeName)
      return _.merge(dataset, datasetTheme)
    }.bind(this)

    chartData.datasets = chartData.datasets.map(applyTheme)

    return chartData
  }

  get chartOptions() {
    return _.merge(this.defaultOptions, this.optionsValue)
  }

  getThemeName(dataset) {
    const theme = dataset.theme

    if (theme == 'undefined') {
      theme = 'none'
    }

    return theme
  }

  get defaultOptions() {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: this.labelMaker,
            font: {
              size: 12,
            },
            maxRotation: 90,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: this.getThemeColors('grey').secondary,
            drawBorder: false,
          },
          ticks: {
            callback: function (value) {
              if (value >= 1000000000) {
                return Math.round(value / 1e9) + 'B'
              } else if (value >= 1000000) {
                return Math.round(value / 1e6) + 'M'
              } else if (value >= 1000) {
                return Math.round(value / 1e3) + 'K'
              }
              return Math.round(value)
            },
            count: 4,
            font: {
              size: 12,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#F3FCFA',
          bodyColor: '#1d1d25',
          bodyFont: {
            size: '16',
            weight: 300,
          },
          borderColor: '#CCCCCC',
          borderWidth: 1,
          boxPadding: 24,
          displayColors: false,
          padding: 16,
          position: 'nearest',
          titleColor: '#1d1d25',
          titleFont: {
            size: '16',
            weight: 400,
          },
        },
      },
    }
  }

  themeOptions(themeName) {
    if (themeName == 'none') {
      return {}
    }

    const colors = this.getThemeColors(themeName)

    return {
      backgroundColor: this.getGradient(colors.secondary),
      fill: true,
      borderColor: colors.primary,
      lineTension: 0.2,
      borderWidth: 4,
      pointRadius: 0,
    }
  }

  getThemeColors(themeColor) {
    const themes = {
      green: {
        primary: '#1A6C59',
        secondary: '#2EAE99',
      },
      blue: {
        primary: '#147EAD',
        secondary: '#8DD5F5',
      },
      yellow: {
        primary: '#E8871E',
        secondary: '#F1B586',
      },
      grey: {
        primary: '#6B6B6B',
        secondary: '#D6D6D6',
      },
    }

    return themes[themeColor]
  }

  getGradient(baseColor) {
    const context = this.element.getContext('2d')
    const gradient = context.createLinearGradient(0, 25, 0, 200)
    gradient.addColorStop(0, this.hexToRGBA(baseColor, 1))
    gradient.addColorStop(0.5, this.hexToRGBA(baseColor, 0.75))
    gradient.addColorStop(1, this.hexToRGBA(baseColor, 0))
    return gradient
  }

  labelMaker(value) {
    let label = this.getLabelForValue(value)

    const timestamp = Date.parse(label)

    if (!isNaN(timestamp)) {
      const date = new Date(timestamp)
      label = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' })
    }

    if (label == this.lastLabel) {
      return
    } else {
      this.lastLabel = label

      if (label.length > 12) {
        return label.slice(0, 10).concat('...')
      } else {
        return label
      }
    }
  }

  hexToRGBA(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
}
