import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['add_item', 'template']
  static values = {
    removalEvent: String,
    focus: String,
  }

  add_association(event) {
    event.preventDefault()
    var target = this.add_itemTarget
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_ID/g, Date.now())
    target.insertAdjacentHTML('beforebegin', content)
    if (this.hasFocusValue) {
      target.previousElementSibling.querySelector(this.focusValue).focus()
    }

    if (target.dataset.removeAfter == 'true') {
      target.remove()
    }
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest('.nested-fields')
    item.remove()
    if (this.hasRemovalEventValue) {
      const e = new Event(`${this.removalEventValue}Removed`)
      window.dispatchEvent(e)
    }
  }
}
