import { Application } from '@hotwired/stimulus'
import { Autocomplete } from 'stimulus-autocomplete'

const application = Application.start()

class CustomAutocomplete extends Autocomplete {
  static targets = ['input', 'hidden', 'results']

  buildURL(query) {
    return `${new URL(this.urlValue, window.location.href).toString()}?q=${query}`
  }

  connect() {
    super.connect()

    this.inputTarget.addEventListener('focus', this.initialFetch, true)
    this.inputTarget.addEventListener('blur', this.clearOnBlur, true)
  }

  disconnect() {
    super.disconnect()

    this.inputTarget.removeEventListener('focus', this.initialFetch, true)
    this.inputTarget.removeEventListener('blur', this.clearOnBlur, true)
  }

  initialFetch = (event) => {
    if (event.target.getAttribute('data-autocomplete-target') == 'input') {
      if (!this.hiddenTarget.value) {
        this.fetchResults('')
      }
    }
  }

  clearOnBlur = (event) => {
    if (event.target.getAttribute('data-autocomplete-target') == 'input') {
      if (this.mouseDown) return
      if (!this.hiddenTarget.value) {
        this.clear()
      }
    }
  }

  clearOnClick = (event) => {
    this.clear()
  }

  clear = () => {
    this.inputTarget.value = ''
    if (this.hasHiddenTarget) this.hiddenTarget.removeAttribute('value')
  }

  onEnterKeydown = (event) => {
    const selected = this.selectedOption
    if (selected && this.resultsShown) {
      this.commit(selected)
    }
    if (!this.hasSubmitOnEnterValue) {
      event.preventDefault()
    }
  }
}
application.register('autocomplete', CustomAutocomplete)
