import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu']

  connect() {
    this.close = this.close.bind(this)
    document.addEventListener('click', this.close)
    document.addEventListener('keyup', this.close)
  }

  toggle() {
    this.menuTarget.classList.toggle('hidden')
  }

  close(e) {
    if (!this.element.contains(e.target) || e.code == 'Escape') {
      this.menuTarget.classList.add('hidden')
    }
  }
}
