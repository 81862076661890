import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pagination']

  paginationTargetConnected(el) {
    const options = {
      rootMargin: '300px',
    }

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })

    const loadMoreCallback = function (entries) {
      if (entries[0].isIntersecting) {
        el.dispatchEvent(clickEvent)
      }
    }

    this.intersectionObserver = new IntersectionObserver(loadMoreCallback, options)
    this.intersectionObserver.observe(el)

    el.style = 'visibility: hidden'
  }

  paginationTargetDisconnect(el) {
    this.intersectionObserver.unobserve(el)
  }
}
