import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    endpoint: String,
    pollInterval: Number, // in ms
    activeClass: String,
    enabled: Boolean
  }

  connect() {
    if(this.enabledValue) {
      this.poll()
      this.setTimer()
    }
  }

  setTimer() {
    this.timeout = setTimeout(() => {
      this.poll()
      this.setTimer()
    }, this.pollIntervalValue)
  }

  repoll() {
    setTimeout(() => {
      this.poll()
    }, 2000)
  }

  poll() {
    fetch(this.endpointValue, { headers: { accept: 'application/json' } })
      .then((response) => response.json())
      .then((data) => {
        if (data.notification_count > 0) {
          this.element.classList.add(this.activeClassValue)
        } else {
          this.element.classList.remove(this.activeClassValue)
        }
      })
  }
}
