import { Controller } from 'stimulus'

// Usage:
// add data-controller="tabs" to the wrapper
// add data-tabs-target="tab" to each tab
// add data-tabs-target="content" to each content wrapper
// add data-content-class="class_name_of_corresponding_content_wrapper" to each tab
export default class extends Controller {
  static targets = ['tab', 'content']

  connect() {
    this.boundChangeTab = this.changeTab.bind(this)

    this.tabTargets.forEach((el) => {
      el.addEventListener('click', this.boundChangeTab)
    })
  }

  disconnect() {
    this.tabTargets.forEach((el) => {
      el.removeEventListener('click', this.boundChangeTab)
    })
  }

  changeTab(e) {
    const targetTab = e.target.closest('[data-tabs-target=tab]')

    this.tabTargets.forEach((el) => {
      el.classList.remove('active')
    })

    targetTab.classList.add('active')

    this.contentTargets.forEach((el) => {
      el.classList.remove('active')
      if (el.classList.contains(targetTab.dataset.contentClass)) {
        el.classList.add('active')
      }
    })
  }
}
