import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['emailToggle', 'email', 'inappToggle', 'inapp']

  connect() {
    this.bulkToggleEmail()
    this.bulkToggleInapp()
  }

  bulkToggleEmail() {
    this.emailTargets.forEach((el) => {
      const hidden = document.querySelector(`[name="${el.name}"][type=hidden]`)
      if (!this.emailToggleTarget.checked) {
        el.setAttribute('disabled', true)
        hidden.setAttribute('disabled', true)
      } else {
        el.removeAttribute('disabled')
        hidden.removeAttribute('disabled')
      }
    })
  }

  bulkToggleInapp() {
    this.inappTargets.forEach((el) => {
      const hidden = document.querySelector(`[name="${el.name}"][type=hidden]`)
      if (!this.inappToggleTarget.checked) {
        el.setAttribute('disabled', true)
        hidden.setAttribute('disabled', true)
      } else {
        el.removeAttribute('disabled')
        hidden.removeAttribute('disabled')
      }
    })
  }
}
