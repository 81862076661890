import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('td').forEach((el) => {
      el.classList.add('clickable')
    })
  }

  openLink(event) {
    if (!event.target.closest('form')) {
      document.location = event.currentTarget.dataset.href
    }
  }
}
