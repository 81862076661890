import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'template']

  convert(event) {
    event.preventDefault()

    var key = this.inputTarget.name
    var value = this.inputTarget.value

    const href = new URL(window.location.href)
    href.searchParams.set(key, value)

    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_NAME/g, value).replace(/TEMPLATE_HREF/g, href)

    this.element.innerHTML = content
  }
}
