import { Controller } from 'stimulus'

export default class extends Controller {
  highlight(e) {
    const newEl = e.target.closest('.request-item')
    document.querySelectorAll('.request-item--active').forEach((el) => {
      el.classList.remove('request-item--active')
      el.classList.add('request-item--viewed')
    })
    newEl.classList.remove('request-item--default')
    newEl.classList.remove('request-item--viewed')
    newEl.classList.add('request-item--active')
    document.getElementById('update_request').parentElement.scrollTo(0, 0)
  }
}
