import { Controller } from 'stimulus'
import { Sortable, Draggable } from '@shopify/draggable'
let debounce = require('lodash/debounce')

export default class extends Controller {
  static targets = ['item']
  static values = {
    path: String,
    scope: String,
    association: String,
  }

  initialize() {
    this.reposition = debounce(this.reposition, 200).bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: '.draggable',
      handle: '.handle',
      exclude: {
        plugins: [Draggable.Plugins.Focusable],
      },
      mirror: {
        constrainDimensions: true,
      },
    })

    this.sortable.on('drag:stop', () => {
      this.dispatch('end')
    })

    this.reposition = this.reposition.bind(this)
    this.element.addEventListener('reorder:end', this.reposition)
  }

  reposition(e) {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const url = new URL(this.pathValue, window.location.origin)

    fetch(url, {
      headers: {
        accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      method: 'PUT',
      body: JSON.stringify(this.buildParams()),
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  buildAttributes() {
    let attributes = []

    this.itemTargets.forEach((el, index) => {
      attributes[el.dataset.reorderId] = {
        id: el.dataset.reorderId,
        position: index + 1,
      }
    })

    return attributes
  }

  buildParams() {
    let params = {}
    params[this.scopeValue] = {}
    params[this.scopeValue][`${this.associationValue}_attributes`] = this.buildAttributes()

    return params
  }
}
