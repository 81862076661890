import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['param']
  static values = {
    filter: String,
  }

  update(e) {
    if (this.paramTarget.value) {
      const pathname = this.filterValue.replace(/\$id/, this.paramTarget.value)
      const url = new URL(pathname, window.location.origin)
      fetch(url, { headers: { accept: 'text/vnd.turbo-stream.html' } })
        .then((response) => response.text())
        .then((html) => Turbo.renderStreamMessage(html))
    }
  }
}
