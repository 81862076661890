import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const flash = this.element

    // listen for when the animation ends
    flash.addEventListener('animationend', listener, false)

    // add the class to trigger animation
    flash.classList.add('fade-out-flash')

    function listener(event) {
      // delete when animation finished
      if (event.type == 'animationend') {
        flash.remove()
      }
    }
  }
}
