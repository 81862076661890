import { Controller } from 'stimulus'

export default class extends Controller {
  // Use this when you need to coerce a <button type=button>
  // to act like a <button type=submit>
  submit(event) {
    const oldType = this.element.getAttribute('type')
    this.element.setAttribute('type', 'submit')
    this.element.form.requestSubmit(this.element)
    this.element.setAttribute('type', oldType)
  }
}
