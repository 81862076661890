import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['anchor', 'content']

  initialize() {
    this.observer = new IntersectionObserver(this.highlightCallback, { threshold: [0.01, 0.25, 0.51] })
  }

  disconnect() {
    this.observer.disconnect()
  }

  highlightCallback(entries) {
    entries.forEach((entry) => {
      const anchor = document.getElementById(entry.target.dataset.anchorId)
      if (!anchor) {
        return
      }

      if (entry.isIntersecting) {
        anchor.style.backgroundColor = `rgba(46,174,143,${(0.07 * Math.ceil(entry.intersectionRatio * 2)) / 2})`
      } else {
        anchor.removeAttribute('style')
      }
    })
  }

  contentTargetConnected(el) {
    this.observer.unobserve(el)

    setTimeout(() => {
      this.observer.observe(el)
    }, 500)
  }

  contentTargetDisconnected(el) {
    this.observer.unobserve(el)
  }

  anchorTargetConnected(el) {
    const content = document.getElementById(el.dataset.contentId)
    this.observer.unobserve(content)

    setTimeout(() => {
      this.observer.observe(content)
    }, 500)
  }
}
